import styled from 'styled-components';
import { shade } from 'polished';
import { defaultTheme } from '../../config';

export const Button = styled.button`
  background: ${defaultTheme.defaultBackground} !important;
  color: ${defaultTheme.defaultColor} !important;
  height: 56px;

  border-radius: 15px;
  border: 0;
  padding: 5 10px;
  min-width: 140px;

  font-weight: 500;
  margin: auto;
  margin-top: 5px;
  font-size: 12px;

  transition: 0.2s all;

  border: 1px solid ${defaultTheme.defaultColor} !important;
  border-radius: 10px;
  margin-right: 5px !important;
  margin-left: 5px !important ;
  box-shadow: rgb(7 13 28 / 25%) 0px 9px 90px;
  &:hover {
    background: ${defaultTheme.defaultColor} !important;
    color: ${defaultTheme.defaultBackground} !important;
  }
`;

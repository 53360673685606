import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import SignIn from '../../SignIn';
import { useAuth } from '../../../hooks/Auth';
import { addYears, isValid } from 'date-fns';
import testCPF from '../../../utils/testCPF';
import ExtraProducts from '../../SignUp/modules/ExtraProducts';
import { useConfiguration } from '../../../hooks/Configuration';
interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainer = ({ context = {} }) => {

  const { configuration } = useConfiguration();


  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { projectHash, eventHash } = useParams<IParams>();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const siglas = readSigla();
  const { addToast } = useToast();
  const { translate } = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [category, setCategory] = useState('');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');

  const [cities, setCities] = useState<Array<IOption>>([]);
  const [stateValue, setStateValue] = useState('');

  const { signIn, user, getTrackingIntegration } = useAuth()
  const [currentEmail, setCurrentEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('');

  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };



  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);


    if (countryISO === 'BR') {
      data.document_type = 'cpf';
    }

    if (countryISO === 'BR') {
      const validCPF = testCPF(data?.document_number);

      if (!validCPF) {
        addToast({ type: 'error', title: 'Preencha um CPF válido' })
        setLoading(false);
        return;
      }
    }

    try {
      if (!data?.birth_date) {
        addToast({ type: 'error', title: 'Preencha a sua data de nascimento' })
        setLoading(false);
        return;
      }

      const getDate = data?.birth_date?.split('/');

      const date = new Date(`${getDate?.[2]}-${getDate?.[1]}-${getDate?.[0]}`);

      if (!isValid(date)) {
        throw ({ 'message': 'Data de nascimento incompleta' });
      }

      if (date < addYears(new Date(), -100)) {
        throw ({ 'message': 'Data de nascimento inválida' });
      }

    } catch (err) {
      console.log(err)
      addToast({ type: 'error', title: 'Preencha uma data de nascimento válida' })
      setLoading(false);
      return;
    }



    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),

        phone: Yup.string()
          .required('Telefone com DDD é obrigatório'),
        position: Yup.string().required('O Profissão é obrigatório'),
        company: Yup.string().required('Informe de qual operação Dasa você faz parte'),

        country: Yup.string().required('País é obrigatório'),
        state: Yup.string().required('Estado é obrigatório'),
        birth_date: Yup.string().required('Informe a sua Data de nascimento'),
        document_type: Yup.string().required('Informe o seu tipo de documento'),
        know_about: Yup.string().required('Pedimos que informe: como ficou sabendo do curso?'),
        extra_5: Yup.string().required('Pedimos que informe: Gostaria de receber futuras comunicações sobre eventos?'),
        term: Yup.string().required('Informe se você autoriza o compartilhamento de seus dados.'),
        password: Yup.string().required('Senha é obrigatória'),
      });
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');



      if (data.position === 'Médico' || data.position === 'Estudante' || data.position === 'Profissional da área da saúde') {

        if (!data.category_id) {
          addToast({ title: 'Por favor, informe sua área / especialidade', type: 'error' });
          setLoading(false);
          return;
        }

      }

      if (data.position === 'Médico') {

        if (!data.crm && countryISO === 'BR') {
          addToast({ title: 'O campo CRM é obrigatório para médicos brasileiros', type: 'error' });
          setLoading(false);
          return;
        }

        if (!data.cpf_familiar && countryISO === 'BR') {
          addToast({ title: 'O estado do CRM é obrigatório para médicos brasileiros', type: 'error' });
          setLoading(false);
          return;
        }



      }

      if (data.category_id === 'Farmácia') {

        if (!data.crm && countryISO === 'BR') {
          addToast({ title: translate('O campo CRF é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }

        if (!data.cpf_familiar && countryISO === 'BR') {
          addToast({ title: translate('O estado do CRF é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }



      }


      if (data.company === 'Outro? Qual?' && !data.extra_4) {
        addToast({ title: 'Por favor, informe o nome da sua organização', type: 'error' });
        setLoading(false);
        return;
      }

      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });




      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;


      const getTracking = getTrackingIntegration();

      const newUser = await api
        .post('/signup', {
          ...data,
          project_id: projectHash ? projectHash : window?.location?.pathname?.indexOf('/podcasts') > 0 ? 'podcasts' : 'biblioteca-cientifica',
          tracking: getTracking || '',
          captcha: captchaValue,
          term: data.term
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      signIn({ email: data.email, name: '', password: data.password })
      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  const handleSendConfirmation = async () => {

    try {
      const confirm = await api.post(`/confirm-subscriber`, { email: currentEmail, project_id: projectHash, event_id: eventHash ? eventHash : '' });

      if (confirm) {
        setSuccess(true);
        setStatusConfirmation('finished');

      }
      setLoading(false);
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao confirmar inscrição' });
      setLoading(false);
    }



  }

  const handleConfirmation = async (data) => {

    data.email = data?.email?.trim()?.toLowerCase() || '';
    const getTracking = getTrackingIntegration();
    try {
      const checkUser = await api.post(`/check-subscriber`, { tracking: getTracking || '', email: data.email, project_id: projectHash, event_id: eventHash || '' });

      if (checkUser) {
        setCurrentEmail(data.email);

        const status = checkUser?.data?.status || 'new-subscriber';
        setStatusConfirmation(checkUser?.data?.status || 'new-subscriber');

        if (status !== 'new-subscriber') {

          signIn({ email: data.email, name: '', password: data?.password })
        }
        setReady(true);

      }

    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao checar usuário, por favor, tente novamente' });
      return
    }


  }


  if (!ready) {

    return (
      <Form style={{ width: '100%', maxWidth: '350px' }} ref={formRefConfirmation} onSubmit={handleConfirmation} initialData={{ country: 'BR', email: currentEmail }}>
        <h1 style={{ color: 'rgb(0,15,64)', fontSize: '18px', marginBottom: '10px' }}>Novo cadastro</h1>
        <p style={{ color: 'rgb(0,15,64)', fontSize: '14px', marginBottom: '20px' }}>Para iniciar, informe seu email</p>
        <Input name="email" placeholder="E-mail" />
        <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> <button className="defaultButton" type="submit">
          Continuar
        </button></aside>
      </Form>)
  }


  if (statusConfirmation === 'confirmated') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Olá participante!</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Identificamos o seu cadastro em nossa plataforma. A sua inscrição no próximo evento está confirmada.</p>

        {user && configuration?.current_event_id_response?.have_extra_products === 'yes' ? <ExtraProducts /> : <></>}

        <Link to={`/app/${projectHash}/login`}><button className="defaultButton" type="button">
          Acessar
        </button></Link>
      </>
    )
  }

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{ color: 'rgb(0, 14, 64)' }}>
                <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> Cadastro realizado com sucesso!</h2>


                {user && configuration?.current_event_id_response?.have_extra_products === 'yes' ? <ExtraProducts /> : <></>}

                <br />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link to={`/app/${projectHash}/login/`} >
                    <button className="defaultButton" type="button">
                      Acessar
                    </button>
                  </Link>
                </div>
                <br />
                {translate("Aproveite e indique para seus amigos!")}
                <Whatsapp
                  text={`Olá! Acabei de me inscrever no ${authTitle}. Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: ${urlWebsite}`}
                  number=""
                  message={translate("Compartilhe!")}
                />
              </p>
            }
          />
        ) : (<>

          <Form ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR' }}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Novo cadastro</h1>

            <Input name="name" placeholder="Nome completo" />
            <Input name="email" placeholder="E-mail" />


            <SelectSimple setCallback={(value) => setPosition(value)}
              options={[
                { value: 'Médico', label: 'Médico' },
                { value: 'Estudante', label: 'Estudante' },
                { value: 'Profissional da área da saúde', label: 'Profissional da área da saúde' },

                { value: 'Outro? Qual?', label: 'Outra?' },
              ]}
              name="position"
              formRef={formRef}
              label="Profissão"
            />
            {position === 'Médico' && <>
              <Input
                name="crm"
                placeholder="CRM"
              />

              <SelectSimple
                options={states}
                name="cpf_familiar"
                formRef={formRef}
                label={'Estado do CRM'}
              />


              <SelectSimple
                options={[


                  { value: 'Acupuntura', label: 'Acupuntura' },
                  { value: 'Alergia e imunologia', label: 'Alergia e imunologia' },
                  { value: 'Anestesiologia', label: 'Anestesiologia' },
                  { value: 'Angiologia', label: 'Angiologia' },
                  { value: 'Cardiologia', label: 'Cardiologia' },
                  { value: 'Cirurgia cardiovascular', label: 'Cirurgia cardiovascular' },
                  { value: 'Cirurgia da mão', label: 'Cirurgia da mão' },
                  { value: 'Cirurgia de cabeça e pescoço', label: 'Cirurgia de cabeça e pescoço' },
                  { value: 'Cirurgia do aparelho digestivo', label: 'Cirurgia do aparelho digestivo' },
                  { value: 'Cirurgia geral', label: 'Cirurgia geral' },
                  { value: 'Cirurgia oncológica', label: 'Cirurgia oncológica' },
                  { value: 'Cirurgia pediátrica', label: 'Cirurgia pediátrica' },
                  { value: 'Cirurgia plástica', label: 'Cirurgia plástica' },
                  { value: 'Cirurgia torácica', label: 'Cirurgia torácica' },
                  { value: 'Cirurgia vascular', label: 'Cirurgia vascular' },
                  { value: 'Clínica médica', label: 'Clínica médica' },
                  { value: 'Coloproctologia', label: 'Coloproctologia' },
                  { value: 'Dermatologia', label: 'Dermatologia' },
                  { value: 'Endocrinologia e metabologia', label: 'Endocrinologia e metabologia' },
                  { value: 'Endoscopia', label: 'Endoscopia' },
                  { value: 'Gastroenterologia', label: 'Gastroenterologia' },
                  { value: 'Genética médica', label: 'Genética médica' },
                  { value: 'Geriatria', label: 'Geriatria' },
                  { value: 'Ginecologia e obstetrícia', label: 'Ginecologia e obstetrícia' },
                  { value: 'Hematologia e hemoterapia', label: 'Hematologia e hemoterapia' },
                  { value: 'Homeopatia', label: 'Homeopatia' },
                  { value: 'Infectologia', label: 'Infectologia' },
                  { value: 'Mastologia', label: 'Mastologia' },
                  { value: 'Medicina de emergência', label: 'Medicina de emergência' },
                  { value: 'Medicina de família e comunidade', label: 'Medicina de família e comunidade' },
                  { value: 'Medicina do trabalho', label: 'Medicina do trabalho' },
                  { value: 'Medicina de tráfego', label: 'Medicina de tráfego' },
                  { value: 'Medicina esportiva', label: 'Medicina esportiva' },
                  { value: 'Medicina física e reabilitação', label: 'Medicina física e reabilitação' },
                  { value: 'Medicina intensiva', label: 'Medicina intensiva' },
                  { value: 'Medicina legal e perícia médica', label: 'Medicina legal e perícia médica' },
                  { value: 'Medicina nuclear', label: 'Medicina nuclear' },
                  { value: 'Medicina preventiva e social', label: 'Medicina preventiva e social' },
                  { value: 'Nefrologia', label: 'Nefrologia' },
                  { value: 'Neurocirurgia', label: 'Neurocirurgia' },
                  { value: 'Neurologia', label: 'Neurologia' },
                  { value: 'Nutrologia', label: 'Nutrologia' },
                  { value: 'Oftalmologia', label: 'Oftalmologia' },
                  { value: 'Oncologia clínica', label: 'Oncologia clínica' },
                  { value: 'Ortopedia e traumatologia', label: 'Ortopedia e traumatologia' },
                  { value: 'Otorrinolaringologia', label: 'Otorrinolaringologia' },
                  { value: 'Patologia', label: 'Patologia' },
                  { value: 'Patologia clínica/medicina laboratorial', label: 'Patologia clínica/medicina laboratorial' },
                  { value: 'Pediatria', label: 'Pediatria' },
                  { value: 'Pneumologia', label: 'Pneumologia' },
                  { value: 'Psiquiatria', label: 'Psiquiatria' },
                  { value: 'Radiologia e diagnóstico por imagem', label: 'Radiologia e diagnóstico por imagem' },
                  { value: 'Radioterapia', label: 'Radioterapia' },
                  { value: 'Reumatologia', label: 'Reumatologia' },
                  { value: 'Urologia', label: 'Urologia' },
                  { value: 'Outra', label: 'Outra' },

                ]}
                name="category_id"
                formRef={formRef}
                label="Especialidade"
              />  </>}



            {position === 'Profissional da área da saúde' ? <SelectSimple
              options={[
                { value: "Enfermagem", label: "Enfermagem" },
                { value: "Fonoaudiologia", label: "Fonoaudiologia" },
                { value: "Psicologia", label: "Psicologia" },
                { value: "Fisioterapia", label: "Fisioterapia" },
                { value: "Farmácia", label: "Farmácia" },
                { value: "Terapia Ocupacional", label: "Terapia Ocupacional" },
                { value: 'Outra', label: 'Outra' },

              ]}
              name="category_id"
              formRef={formRef}
              label="Área"
              setCallback={(value) => { setCategory(value) }}
            /> : <></>}

            {category === 'Farmácia' ? <>

              <Input
                name="crm"
                placeholder="CRF"
              />

              <SelectSimple
                options={states}
                name="cpf_familiar"
                formRef={formRef}
                label={translate('Estado do CRF')}
              />

            </> : <></>}

            {position === 'Estudante' ?
              <SelectSimple
                name="category_id"
                formRef={formRef}
                label="Área"
                options={[
                  { value: "Biomedicina", label: "Biomedicina" },
                  { value: "Biotecnologia", label: "Biotecnologia" },
                  { value: "Educação Física", label: "Educação Física" },
                  { value: "Enfermagem", label: "Enfermagem" },
                  { value: "Farmácia", label: "Farmácia" },
                  { value: "Fisioterapia", label: "Fisioterapia" },
                  { value: "Fonoaudiologia", label: "Fonoaudiologia" },
                  { value: "Medicina", label: "Medicina" },
                  { value: "Nutrição", label: "Nutrição" },
                  { value: "Odontologia", label: "Odontologia" },
                  { value: "Pedagogia", label: "Pedagogia" },
                  { value: "Psicologia", label: "Psicologia" },
                  { value: "Saúde Coletiva", label: "Saúde Coletiva" },
                  { value: "Serviço Social", label: "Serviço Social" },
                  { value: 'Outra', label: 'Outra' },
                ]} />


              : <></>}





            <SelectSimple setCallback={(value) => setOtherCompany(value)}
              options={[
                { value: 'Dasa', label: 'Dasa' },
                { value: 'Outro? Qual?', label: 'Outra?' },
              ]}
              name="company"
              formRef={formRef}
              label="Organização"
            />

            {otherCompany === 'Outro? Qual?' &&

              <Input
                name="extra_4"
                placeholder="Informe o nome da organização"
              />
            }


            <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={'Pais'} />



            {countryISO !== 'BR' ?

              <SelectSimple options={[
                { value: 'passport', label: translate('Passaporte') },
                { value: 'other', label: translate('Documento local') },
              ]} name="document_type" label={'Tipo de documento pessoal'}
              />



              : <></>}

            {countryISO === 'BR' ?
              <MaskedInput
                mask={'999.999.999-99'}
                name="document_number"
                placeholder={'CPF'}
              />
              : <Input type='text' name="document_number" placeholder={translate("Número do documento")} />}

            <MaskedInput
              mask="99/99/9999"
              name="birth_date"
              placeholder="Data de nascimento (DD/MM/AAAA)"
            />


            {withPassword && <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder={translate('Cadastre sua senha de acesso')}
            />}



            {countryISO === 'BR' ? <SelectSimple setCallback={(value) => {
              setStateValue(value);
            }} options={states} name="state" label="Estado" /> :

              <Input name="state" placeholder={'Província / Região da Fundação'} />}



            {countryISO === 'BR' ? <MaskedInput
              mask="(99) 999999999"
              name="phone"
              placeholder="Telefone com DDD"
            /> : <Input name="phone" placeholder={'Telefone com DDD'} />}

            <SelectSimple

              options={[
                { label: 'Visitação Médica', value: 'Visitação Médica' },
                { label: 'NAM - Núcleo de Assessoria Médica', value: 'NAM - Núcleo de Assessoria Médica' },

                { label: 'WhatsApp', value: 'WhatsApp' },
                { label: 'Indicação', value: 'Indicação' },
                { label: 'Instagram', value: 'Instagram' },
                { label: 'Facebook', value: 'Facebook' },
                { label: 'Linkedin', value: 'Linkedin' },
                { label: 'E-mail marketing', value: 'E-mail marketing' },
                { label: 'SMS', value: 'SMS' },
                { label: 'Workplace', value: 'Workplace' },
                { label: 'Google', value: 'Google' },
                { label: 'Outros', value: 'Outros' },



              ]}
              name="know_about"
              formRef={formRef}
              label={window?.location?.pathname?.indexOf('/biblioteca') >= 0 ? "Como ficou sabendo do evento/conteúdo científico?" : "Como ficou sabendo do evento?"}
            />

            <SelectSimple

              options={[
                { label: 'Sim', value: 'Sim' },
                { label: 'Não', value: 'Não' },


              ]}
              name="extra_5"
              formRef={formRef}
              label={window?.location?.pathname?.indexOf('/biblioteca') >= 0 ? "Gostaria de receber futuras comunicações sobre eventos/novos conteúdos publicados?" : "Gostaria de receber futuras comunicações sobre eventos?"}
            />

            <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>
              <CheckboxInput
                style={{ display: 'flex' }}
                name="term"
                unique={true}
                preText={(
                  <>
                    {' '}
                    Estou ciente de que estou fornecendo à DASA EDUCA, parceiros e patrocinadores meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
                    {' '}

                  </>
                )}
                options={[
                  {
                    id: `Sim, eu autorizo o compartilhamento dos meus dados`,
                    label: 'Sim, eu autorizo o compartilhamento dos meus dados',
                    value: `Sim, eu autorizo o compartilhamento dos meus dados`,
                  },
                  {
                    id: `Não autorizo o compartilhamento dos meus dados`,
                    label: 'Não autorizo o compartilhamento dos meus dados',
                    value: `Não autorizo o compartilhamento dos meus dados`,
                  },
                ]}
              />

              <p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {'Ao me cadastrar, afirmo que li a'}
                {' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {'Política de Privacidade'}
                    {' '}
                  </Link>
                  {' '}
                </strong>
                {'desta plataforma.'}
              </p>
            </div>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                Finalizar inscrição


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;

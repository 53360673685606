import React, { useRef } from 'react';
import { Form } from "@unform/web"
import { useModal } from '../../../hooks/Modal';
import Button from '../../../components/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchaKey } from '../../../config';
import api from '../../../services/api';
import { useLoading } from '../../../hooks/LoadingHook';

interface ICaptcha {
    getValue(): string;
}


const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
};

function useFirstPassword() {
    const { addModal, removeModal } = useModal();
    const { addLoading, removeLoading } = useLoading();
    const recaptchaRef = useRef(null);

    const recoverPassword = async (data) => {

        addLoading({ title: 'Enviando solicitação', key: 'loading' })
        const captchaValue =
            getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
            false;

        try {

            const response = await api.post('/recover-password', {
                email: data.email,
                captcha: captchaValue,
                project_id: data?.project_id
            });

            removeModal('recoveryPassord');


            addModal({
                theme: 'whiteModalMini', key: 'recoveryPassord',
                title: '', content: <>
                    <h2 style={{ width: '100%', color: '#333', fontSize: '18px', textAlign: 'center' }}>Senha solicitada com sucesso</h2>
                    <p style={{ width: '100%', color: '#333' }}>
                        Uma solicitação de recuperação de senha foi encaminhada para o seu e-mail.
                    </p>

                </>



            });

            removeLoading('loading')

        }
        catch (err) {

            addModal({
                theme: 'whiteModalMini', key: 'recoveryPassordError',
                title: '', content: <>
                    <h2 style={{ width: '100%', color: '#333', fontSize: '18px', textAlign: 'center' }}>Erro</h2>
                    <p style={{ width: '100%', color: '#333' }}>
                        {err?.response?.data?.error || err?.response?.data?.message || 'Erro ao solicitar senha'}
                    </p>
                </>



            });
            removeLoading('loading')
        }

    }

    const requestFirstPassword = (email, project_id = '') => {

        addModal({
            theme: 'whiteModalMini', key: 'recoveryPassord',
            title: '', content: <div className='column gap-xs'>
                <h2 style={{ width: '100%', color: '#333', fontSize: '18px', textAlign: 'center' }}>Crie uma nova senha</h2>
                <p style={{ width: '100%', color: '#333' }}>
                    Identificamos que o seu cadastro não possui uma senha cadastrada.

                    Confirme que você não é um robô e clique em solicitar nova senha.

                    A solicitação será enviada para o seu e-mail.
                </p>
                <Form onSubmit={() => recoverPassword({ email, project_id })}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
                    <aside className='row full-width'><Button>Solicitar nova senha</Button>
                    </aside>

                </Form>
            </div>



        });

    }

    return { requestFirstPassword }
}

export default useFirstPassword;
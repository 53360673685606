import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
import CompleteSaleFields from './CompleteSaleFields';
import testCPF from '../../../utils/testCPF';
import { addYears, isValid } from 'date-fns';
import { useConfiguration } from '../../../hooks/Configuration';
import { useAuth } from '../../../hooks/Auth';
import CourseProductsSubscriptionModule from '../../CourseProductSubscription';
interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
  productId: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRefConfirmationOK = useRef<FormHandles>(null);
  const formRefConfirmation = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { projectHash, eventHash, productId } = useParams<IParams>();
  const { configuration } = useConfiguration();
  const [emailRecord, setEmailRecord] = useState('');

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const siglas = readSigla();
  const { addToast } = useToast();
  const { translate, language } = useLanguage();
  const { signIn } = useAuth();
  const [countryISO, setCountryISO] = useState('BR');
  const [category, setCategory] = useState('');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');
  const [product, setProduct] = useState<Record<string, any>>({});
  const [cities, setCities] = useState<Array<IOption>>([]);
  const [stateValue, setStateValue] = useState('');


  const [currentEmail, setCurrentEmail] = useState('');
  const [ready, setReady] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState('');

  const { getTrackingIntegration } = useAuth();

  const allowedPositions = product?.allowed_categories?.length > 0 ?

    product?.allowed_categories?.reduce((prev, item) => {

      const split = item?.split('>');

      prev.push({ label: split?.[0]?.trim(), value: split?.[0]?.trim() })

      return prev;

    }, [])


    : [];

  const allowedCategories = product?.allowed_categories?.length > 0 ?

    product?.allowed_categories?.reduce((prev, item) => {

      const split = item?.split('>');

      if (split?.[1] && split?.[1]?.trim()) {

        prev.push({ label: split?.[1]?.trim(), value: split?.[1]?.trim() })
      }



      return prev;

    }, [])


    : [];

  const callCityApi = async (value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if (response) {

      const values: Array<IOption> = [];

      response.data.map(city => {
        values.push({ value: city.nome, label: city.nome });
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  }, [stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLoginUpdate = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});

      const fieldsValues: Record<string, any> = {};
      let fieldsText = '';
      const shape = {

      };

      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`Campo ${translate(f.label)} é obrigatório`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;


          x++;

        })


      }


      const schema = Yup.object().shape(shape);



      await schema.validate(data, { abortEarly: false });

      const content: Record<string, any> = {
        ...data,
        email: emailRecord,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        product: product._id.toString(),
        fields: { ...fieldsValues },
        fields_text: fieldsText,
      };



      const newUser = await api
        .post('/signup-update', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    const fieldsValues: Record<string, any> = {};
    let fieldsText = '';

    if (countryISO === 'BR') {
      data.document_type = 'cpf';
    }

    try {
      if (!data?.birth_date) {
        addToast({ type: 'error', title: translate('Preencha a sua data de nascimento') })
        setLoading(false);
        return;
      }

      const getDate = data?.birth_date?.split('/');

      const date = new Date(`${getDate?.[2]}-${getDate?.[1]}-${getDate?.[0]}`);

      if (!isValid(date)) {
        throw ({ 'message': translate('Data de nascimento incompleta') });
      }

      if (date < addYears(new Date(), -100)) {
        throw ({ 'message': translate('Data de nascimento inválida') });
      }

    } catch (err) {
      console.log(err)
      addToast({ type: 'error', title: translate('Preencha uma data de nascimento válida') })
      setLoading(false);
      return;
    }


    try {
      formRef.current?.setErrors({});


      const shape = {
        name: Yup.string().required(translate('Nome obrigatório')),
        email: Yup.string()
          .required(translate('Email obrigatório'))
          .email('Preencha um email válido'),

        phone: Yup.string()
          .required(translate('Telefone com DDD é obrigatório')),
        position: Yup.string().required(translate('O Profissão é obrigatório')),
        company: Yup.string().required(translate('Informe de qual operação Dasa você faz parte')),
        birth_date: Yup.string().required(translate('Informe a sua Data de nascimento')),
        document_type: Yup.string().required(translate('Informe o seu tipo de documento')),
        country: Yup.string().required(translate('País é obrigatório')),
        state: Yup.string().required(translate('Estado é obrigatório')),

        know_about: Yup.string().required(translate('Pedimos que informe: como ficou sabendo do curso?')),
        extra_5: Yup.string().required(translate('Pedimos que informe: Gostaria de receber futuras comunicações sobre eventos?')),
        term: Yup.string().required(translate('Informe se você autoriza o compartilhamento de seus dados.')),
        password: Yup.string().required('Senha é obrigatória'),
      };




      if (product?.fields && product?.fields.length > 0) {


        const fieldsKeys: Array<string> = [];


        let x = 1;
        product?.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`${translate("Campo")} ${translate(f.label)} ${translate("é obrigatório")}`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = data?.[f.field];

          if (x > 1) {
            fieldsText = `${fieldsText} | `;
          }

          fieldsText = `${fieldsText}${data?.[f.field]}`;

          x++;

        })


      }


      const schema = Yup.object().shape(shape);
      data.phone = data?.phone?.toString()?.replace(/[^0-9]/g, '');


      if (countryISO === 'BR') {
        const validCPF = testCPF(data?.document_number);

        if (!validCPF) {
          addToast({ type: 'error', title: translate('Preencha um CPF válido') })
          setLoading(false);
          return;
        }
      }




      if (data.position === 'Médico' || data.position === 'Estudante' || data.position === 'Profissional da área da saúde') {

        if (!data.category_id) {
          addToast({ title: translate('Por favor, informe sua área / especialidade'), type: 'error' });
          setLoading(false);
          return;
        }

      }



      if (data.position === 'Médico') {

        if (!data.crm && countryISO === 'BR') {
          addToast({ title: translate('O campo CRM é obrigatório para médicos brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }

        if (!data.cpf_familiar && countryISO === 'BR') {
          addToast({ title: translate('O estado do CRM é obrigatório para médicos brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }



      }

      if (data.category_id === 'Farmácia' && data.position === 'Profissional da área da saúde') {

        if (!data.crm && countryISO === 'BR') {
          addToast({ title: translate('O campo CRF é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }

        if (!data.cpf_familiar && countryISO === 'BR') {
          addToast({ title: translate('O estado do CRF é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }



      }


      if (data.category_id === 'Nutrição' && data.position === 'Profissional da área da saúde') {

        if (!data.crm && countryISO === 'BR') {
          addToast({ title: translate('O campo CRN é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }

        if (!data.cpf_familiar && countryISO === 'BR') {
          addToast({ title: translate('O estado do CRN é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }



      }

      if (data.category_id === 'Fisioterapia' && data.position === 'Profissional da área da saúde') {

        if (!data.crm && countryISO === 'BR') {
          addToast({ title: translate('O campo CREFITO é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }

        if (!data.cpf_familiar && countryISO === 'BR') {
          addToast({ title: translate('O estado do CREFITO é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }



      }

      if (data.category_id === 'Enfermagem' && data.position === 'Profissional da área da saúde') {

        if (!data.crm && countryISO === 'BR') {
          addToast({ title: translate('O campo CRE é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }

        if (!data.cpf_familiar && countryISO === 'BR') {
          addToast({ title: translate('O estado do CRE é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }



      }

      if (data.category_id === 'Fonoaudiologia' && data.position === 'Profissional da área da saúde') {

        if (!data.crm && countryISO === 'BR') {
          addToast({ title: translate('O campo CRF é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }

        if (!data.cpf_familiar && countryISO === 'BR') {
          addToast({ title: translate('O estado do CRF é obrigatório para brasileiros'), type: 'error' });
          setLoading(false);
          return;
        }



      }

      if (data.company === 'Outro? Qual?' && !data.extra_4) {
        addToast({ title: translate('Por favor, informe o nome da sua organização'), type: 'error' });
        setLoading(false);
        return;
      }

      data.email = data?.email?.trim()?.toLowerCase() || '';

      await schema.validate(data, { abortEarly: false });




      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const getTracking = getTrackingIntegration();

      const content: Record<string, any> = {
        ...data,
        project_id: projectHash,
        event_id: eventHash ? eventHash : '',
        captcha: captchaValue,
        term: data.term,
        fields: { ...fieldsValues },
        fields_text: fieldsText,
        language: language || 'ptBr',
        tracking: getTracking || ''
      };

      if (product) {
        content.product = product?._id?.toString();
      }


      const newUser = await api
        .post('/signup', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      if (window?.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      signIn({ email: data?.email, password: data?.password, project_id: projectHash })
      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  const handleSendConfirmation = async () => {

    try {
      const confirm = await api.post(`/confirm-subscriber`, { email: currentEmail, project_id: projectHash, event_id: eventHash ? eventHash : '', product: productId ? productId : '' });

      if (confirm) {
        setSuccess(true);
        setStatusConfirmation('finished');

      }
      setLoading(false);
    }
    catch (err) {
      addToast({ type: 'error', title: 'Erro ao confirmar inscrição' });
      setLoading(false);
    }



  }

  const handleConfirmation = async (data) => {

    data.email = data?.email?.trim()?.toLowerCase() || '';
    const getTracking = getTrackingIntegration();
    try {
      const checkUser = await api.post(`/check-subscriber`, { tracking: getTracking, email: data.email, project_id: projectHash, event_id: eventHash || '', product: productId ? productId : '' });




      if (checkUser) {
        setCurrentEmail(data.email);
        setStatusConfirmation(checkUser?.data?.status || 'new-subscriber');
        if (checkUser?.data?.product?._id) {

          setProduct(checkUser?.data?.product);
        }
        setEmailRecord(data.email);


        if (checkUser?.data?.status === 'confirmated') {
          // signIn({ email: data.email, project_id: projectHash })
        }

        setReady(true);

      }

    }
    catch (err) {
      addToast({ type: 'error', title: err?.response?.data?.message || translate('Erro ao checar usuário, por favor, tente novamente') });
      return
    }


  }



  if (configuration?.only_allowed_products === 'Sim' && !productId) {


    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{translate("Evento exclusivo")}</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{`${translate("Você não pode acessar esta aba sem uma categoria selecionada")}`}</p>

        <Link to={`/app/${projectHash}/`}><button className="defaultButton" type="button">
          {translate("Voltar")}
        </button></Link>
      </>
    )
  }


  if (!ready) {

    return (
      <Form style={{ width: '100%', maxWidth: '350px' }} ref={formRefConfirmation} onSubmit={handleConfirmation} initialData={{ country: 'BR', email: currentEmail }}>
        <h1 style={{ color: 'rgb(0,15,64)', fontSize: '18px', marginBottom: '10px' }}>{translate("Nova inscrição")}</h1>
        <p style={{ color: 'rgb(0,15,64)', fontSize: '14px', marginBottom: '20px' }}>{translate("Para iniciar, informe seu email")}</p>
        <Input name="email" placeholder="E-mail" />
        <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}> <button className="defaultButton" type="submit">
          {translate("Continuar")}
        </button></aside>
      </Form>)
  }


  if (statusConfirmation === 'productHashWrong') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{translate("Evento exclusivo")}</h1>



        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{`${translate("As inscrições para este eventos são exclusivas e não foi possível identificar a categoria selecionada")}`}</p>



        <Link to={`/app/${projectHash}/`}><button className="defaultButton" type="button">
          {translate("Voltar")}
        </button></Link>
      </>
    )
  }


  if (statusConfirmation === 'exclusive') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{translate("Evento exclusivo")}</h1>

        {productId ? <>

          <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{`${translate("As inscrições para esta categoria são exclusivas para ")} ${translate(product?.allowed_categories?.[0])}`}</p>


        </> : <>
          <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{`${translate("As inscrições para este eventos são exclusivas para ")} ${translate(configuration?.exclusive)}`}</p>
        </>}
        <Link to={`/app/${projectHash}/`}><button className="defaultButton" type="button">
          {translate("Voltar")}
        </button></Link>
      </>
    )
  }

  if (statusConfirmation === 'full') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{translate("Inscrições esgotadas")}</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{translate("Infelizmente as vagas para este evento estão esgotadas")}</p>

        <Link to={`/app/${projectHash}/`}><button className="defaultButton" type="button">
          {translate("Voltar")}
        </button></Link>
      </>
    )
  }

  if (statusConfirmation === 'finished') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{translate("Inscrições encerradas")}</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{translate("Agradecemos seu interesse, mas as vagas estão encerradas")}</p>

        <Link to={`/app/${projectHash}/`}><button className="defaultButton" type="button">
          {translate("Voltar")}
        </button></Link>
      </>
    )
  }

  if (statusConfirmation === 'finishedExtra') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{translate("Inscrição finalizada com sucesso!")}</h1>


        <Link to={`/app/${projectHash}/login`}><button className="defaultButton" type="button">
          {translate("Acessar")}
        </button></Link>
      </>
    )
  }


  if (statusConfirmation === 'confirmated') {

    return (
      <>

        <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{translate("Olá participante!")}</h1>

        <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px', textAlign: 'center' }}>{configuration?.theme_data?.add_extra_activities === 'yes' ? translate("Inscrição concluida") : translate("Identificamos o seu cadastro em nossa plataforma. A sua inscrição no próximo evento está confirmada.")}</p>



        {configuration?.theme_data?.add_extra_activities === 'yes' ? <CourseProductsSubscriptionModule /> : <></>}
        {configuration?.theme_data?.add_extra_activities === 'yes' ? <><button onClick={() => setStatusConfirmation('finishedExtra')} className='defaultButtonReverse'>Finalizar</button> <br /></> : <></>}




        {configuration?.theme_data?.add_extra_activities === 'yes' ? <Link style={{ marginTop: '15px' }} to={`/app/${configuration?.url}/programacao`}><button className='defaultButton'>Confira a programação</button></Link> : <></>}

        <Link to={`/app/${projectHash}/login`}><button className="defaultButton" type="button">
          {translate("Acessar")}
        </button></Link>
      </>
    )
  }



  if (statusConfirmation === 'update') {
    return <>
      {loading === true && (
        <Loader message={translate("Preparando seu cadastro...")} position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{ color: 'rgb(0, 14, 64)' }}>
                <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> {translate("Inscrição concluida")}</h2>
                <br />

                {configuration?.theme_data?.add_extra_activities === 'yes' ? <CourseProductsSubscriptionModule /> : <></>}
                {configuration?.theme_data?.add_extra_activities === 'yes' ? <><button onClick={() => setStatusConfirmation('finishedExtra')} className='defaultButtonReverse'>Finalizar</button> <br /></> : <></>}



                {configuration?.theme_data?.add_extra_activities === 'yes' ? <Link style={{ marginTop: '15px' }} to={`/app/${configuration?.url}/programacao`}><button className='defaultButton'>Confira a programação</button></Link> : <></>}

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link to={`/app/${projectHash}/login/`} >
                    <button className="defaultButton" type="button">
                      {translate("Acessar")}
                    </button>
                  </Link>
                </div>
                <br />
                {translate("Aproveite e indique para seus amigos!")}
                <Whatsapp
                  text={`${translate("Olá! Acabei de me inscrever no")} ${authTitle}. ${translate("Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link")}: ${urlWebsite}`}
                  number=""
                  message={translate("Compartilhe!")}
                />
              </p>
            }
          />
        ) : (<>

          <Form ref={formRef} style={{ padding: '15px', minWidth: '320px' }} onSubmit={handleNewLoginUpdate} initialData={{}}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>{translate("Completar inscrição")}</h1>

            {product && product?.fields && product?.fields.length > 0 ? <CompleteSaleFields fields={product.fields} /> : <></>}

            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                {translate("Finalizar inscrição")}


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  }



  return (
    <>
      {loading === true && (
        <Loader message={translate("Preparando seu cadastro...")} position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{ color: 'rgb(0, 14, 64)' }}>
                <h2 className="titleH2" style={{ color: 'rgb(0, 14, 64)' }}> {translate("Cadastro realizado com sucesso!")}</h2>
                <br />

                {configuration?.theme_data?.add_extra_activities === 'yes' ? <CourseProductsSubscriptionModule /> : <></>}

                {configuration?.theme_data?.add_extra_activities === 'yes' ? <><button onClick={() => setStatusConfirmation('finishedExtra')} className='defaultButtonReverse'>Finalizar</button> <br /></> : <></>}



                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Link to={`/app/${projectHash}/login/`} >
                    <button className="defaultButton" type="button">
                      {translate("Acessar")}
                    </button>
                  </Link>
                </div>
                <br />
                {translate("Aproveite e indique para seus amigos!")}
                <Whatsapp
                  text={`${translate("Olá! Acabei de me inscrever no")} ${authTitle}. ${translate("Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link")}: ${urlWebsite}`}
                  number=""
                  message={translate("Compartilhe!")}
                />
              </p>
            }
          />
        ) : (<>

          <Form ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR', email: currentEmail }}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>{translate("Novo cadastro")}</h1>

            {configuration?.exclusive ? <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }} >{`${translate("Evento exclusivo para ")} ${translate(configuration?.exclusive)}`}</p> : <></>}

            <Input name="name" placeholder="Nome completo" />
            <Input name="email" placeholder="E-mail" />


            <SelectSimple setCallback={(value) => setPosition(value)}
              options={configuration?.exclusive ? [{ value: configuration?.exclusive, label: translate(configuration?.exclusive) },] :
                allowedPositions?.length > 0 ? allowedPositions : [
                  { value: 'Médico', label: translate('Médico') },
                  { value: 'Estudante', label: translate('Estudante') },
                  { value: 'Profissional da área da saúde', label: translate('Profissional da área da saúde') },

                  { value: 'Outro', label: translate('Outra') },
                ]}
              name="position"
              formRef={formRef}
              label="Profissão"
            />
            {position === 'Médico' && <>
              <Input
                name="crm"
                placeholder="CRM"
              />

              <SelectSimple
                options={states}
                name="cpf_familiar"
                formRef={formRef}
                label={translate('Estado do CRM')}
              />

              <SelectSimple
                options={allowedCategories?.length > 0 ? allowedCategories : [


                  { value: 'Acupuntura', label: translate('Acupuntura') },
                  { value: 'Alergia e imunologia', label: translate('Alergia e imunologia') },
                  { value: 'Anestesiologia', label: translate('Anestesiologia') },
                  { value: 'Angiologia', label: translate('Angiologia') },
                  { value: 'Cardiologia', label: translate('Cardiologia') },
                  { value: 'Cirurgia cardiovascular', label: translate('Cirurgia cardiovascular') },
                  { value: 'Cirurgia da mão', label: translate('Cirurgia da mão') },
                  { value: 'Cirurgia de cabeça e pescoço', label: translate('Cirurgia de cabeça e pescoço') },
                  { value: 'Cirurgia do aparelho digestivo', label: translate('Cirurgia do aparelho digestivo') },
                  { value: 'Cirurgia geral', label: translate('Cirurgia geral') },
                  { value: 'Cirurgia oncológica', label: translate('Cirurgia oncológica') },
                  { value: 'Cirurgia pediátrica', label: translate('Cirurgia pediátrica') },
                  { value: 'Cirurgia plástica', label: translate('Cirurgia plástica') },
                  { value: 'Cirurgia torácica', label: translate('Cirurgia torácica') },
                  { value: 'Cirurgia vascular', label: translate('Cirurgia vascular') },
                  { value: 'Clínica médica', label: translate('Clínica médica') },
                  { value: 'Coloproctologia', label: translate('Coloproctologia') },
                  { value: 'Dermatologia', label: translate('Dermatologia') },
                  { value: 'Endocrinologia e metabologia', label: translate('Endocrinologia e metabologia') },
                  { value: 'Endoscopia', label: translate('Endoscopia') },
                  { value: 'Gastroenterologia', label: translate('Gastroenterologia') },
                  { value: 'Genética médica', label: translate('Genética médica') },
                  { value: 'Geriatria', label: translate('Geriatria') },
                  { value: 'Ginecologia e obstetrícia', label: translate('Ginecologia e obstetrícia') },
                  { value: 'Hematologia e hemoterapia', label: translate('Hematologia e hemoterapia') },
                  { value: 'Homeopatia', label: translate('Homeopatia') },
                  { value: 'Infectologia', label: translate('Infectologia') },
                  { value: 'Mastologia', label: translate('Mastologia') },
                  { value: 'Medicina de emergência', label: translate('Medicina de emergência') },
                  { value: 'Medicina de família e comunidade', label: translate('Medicina de família e comunidade') },
                  { value: 'Medicina do trabalho', label: translate('Medicina do trabalho') },
                  { value: 'Medicina de tráfego', label: translate('Medicina de tráfego') },
                  { value: 'Medicina esportiva', label: translate('Medicina esportiva') },
                  { value: 'Medicina física e reabilitação', label: translate('Medicina física e reabilitação') },
                  { value: 'Medicina intensiva', label: translate('Medicina intensiva') },
                  { value: 'Medicina legal e perícia médica', label: translate('Medicina legal e perícia médica') },
                  { value: 'Medicina nuclear', label: translate('Medicina nuclear') },
                  { value: 'Medicina preventiva e social', label: translate('Medicina preventiva e social') },
                  { value: 'Nefrologia', label: translate('Nefrologia') },
                  { value: 'Neurocirurgia', label: translate('Neurocirurgia') },
                  { value: 'Neurologia', label: translate('Neurologia') },
                  { value: 'Nutrologia', label: translate('Nutrologia') },
                  { value: 'Oftalmologia', label: translate('Oftalmologia') },
                  { value: 'Oncologia clínica', label: translate('Oncologia clínica') },
                  { value: 'Ortopedia e traumatologia', label: translate('Ortopedia e traumatologia') },
                  { value: 'Otorrinolaringologia', label: translate('Otorrinolaringologia') },
                  { value: 'Patologia', label: translate('Patologia') },
                  { value: 'Patologia clínica/medicina laboratorial', label: translate('Patologia clínica/medicina laboratorial') },
                  { value: 'Pediatria', label: translate('Pediatria') },
                  { value: 'Pneumologia', label: translate('Pneumologia') },
                  { value: 'Psiquiatria', label: translate('Psiquiatria') },
                  { value: 'Radiologia e diagnóstico por imagem', label: translate('Radiologia e diagnóstico por imagem') },
                  { value: 'Radioterapia', label: translate('Radioterapia') },
                  { value: 'Reumatologia', label: translate('Reumatologia') },
                  { value: 'Urologia', label: translate('Urologia') },
                  { value: 'Outra', label: translate('Outra') },

                ]}
                name="category_id"
                formRef={formRef}
                label={translate("Especialidade")}
              /> </>}

            {position === 'Profissional da área da saúde' ? <SelectSimple
              options={allowedCategories?.length > 0 ? allowedCategories : [
                { value: "Enfermagem", label: translate("Enfermagem") },
                { value: "Fonoaudiologia", label: translate("Fonoaudiologia") },
                { value: "Psicologia", label: translate("Psicologia") },
                { value: "Fisioterapia", label: translate("Fisioterapia") },
                { value: "Farmácia", label: translate("Farmácia") },
                { value: "Nutrição", label: "Nutrição" },
                { value: "Terapia Ocupacional", label: translate("Terapia Ocupacional") },
                { value: 'Outra', label: translate('Outra') },

              ]}
              name="category_id"
              formRef={formRef}
              label={translate("Área")}
              setCallback={(value) => { setCategory(value) }}
            /> : <></>}

            {category === 'Farmácia' ? <>

              <Input
                name="crm"
                placeholder="CRF"
              />

              <SelectSimple
                options={states}
                name="cpf_familiar"
                formRef={formRef}
                label={translate('Estado do CRF')}
              />

            </> : <></>}
            {category === 'Fisioterapia' ? <>

              <Input
                name="crm"
                placeholder="CREFITO"
              />

              <SelectSimple
                options={states}
                name="cpf_familiar"
                formRef={formRef}
                label={translate('Estado do CREFITO')}
              />

            </> : <></>}

            {category === 'Nutrição' ? <>

              <Input
                name="crm"
                placeholder="CRN"
              />

              <SelectSimple
                options={states}
                name="cpf_familiar"
                formRef={formRef}
                label={translate('Estado do CRN')}
              />

            </> : <></>}

            {category === 'Enfermagem' ? <>

              <Input
                name="crm"
                placeholder="CRE"
              />

              <SelectSimple
                options={states}
                name="cpf_familiar"
                formRef={formRef}
                label={translate('Estado do CRE')}
              />

            </> : <></>}


            {category === 'Fisioterapia' ? <>

              <Input
                name="crm"
                placeholder="CRF"
              />

              <SelectSimple
                options={states}
                name="cpf_familiar"
                formRef={formRef}
                label={translate('Estado do CRF')}
              />

            </> : <></>}

            {category === 'Fonoaudiologia' ? <>

              <Input
                name="crm"
                placeholder="CRF"
              />

              <SelectSimple
                options={states}
                name="cpf_familiar"
                formRef={formRef}
                label={translate('Estado do CRF')}
              />

            </> : <></>}

            {position === 'Estudante' ?
              <SelectSimple
                name="category_id"
                formRef={formRef}
                label={translate("Área")}
                options={allowedCategories?.length > 0 ? allowedCategories : [
                  { value: "Administração", label: translate("Administração") },
                  { value: "Agronomia", label: translate("Agronomia") },
                  { value: "Arquitetura e Urbanismo", label: translate("Arquitetura e Urbanismo") },
                  { value: "Arquivologia", label: translate("Arquivologia") },
                  { value: "Artes Visuais", label: translate("Artes Visuais") },
                  { value: "Biblioteconomia", label: translate("Biblioteconomia") },
                  { value: "Biologia", label: translate("Biologia") },
                  { value: "Biomedicina", label: translate("Biomedicina") },
                  { value: "Biotecnologia", label: translate("Biotecnologia") },
                  { value: "Ciência da Computação", label: translate("Ciência da Computação") },
                  { value: "Ciências Sociais", label: translate("Ciências Sociais") },
                  { value: "Comunicação Social", label: translate("Comunicação Social") },
                  { value: "Contabilidade", label: translate("Contabilidade") },
                  { value: "Dança", label: translate("Dança") },
                  { value: "Design", label: translate("Design") },
                  { value: "Direito", label: translate("Direito") },
                  { value: "Economia", label: translate("Economia") },
                  { value: "Educação Física", label: translate("Educação Física") },
                  { value: "Enfermagem", label: translate("Enfermagem") },
                  { value: "Engenharia Ambiental", label: translate("Engenharia Ambiental") },
                  { value: "Engenharia Civil", label: translate("Engenharia Civil") },
                  { value: "Engenharia Elétrica", label: translate("Engenharia Elétrica") },
                  { value: "Engenharia Física", label: translate("Engenharia Física") },
                  { value: "Engenharia Hídrica", label: translate("Engenharia Hídrica") },
                  { value: "Engenharia Mecânica", label: translate("Engenharia Mecânica") },
                  { value: "Engenharia Metalúrgica", label: translate("Engenharia Metalúrgica") },
                  { value: "Engenharia Química", label: translate("Engenharia Química") },
                  { value: "Engenharia de Alimentos", label: translate("Engenharia de Alimentos") },
                  { value: "Engenharia de Computação", label: translate("Engenharia de Computação") },
                  { value: "Engenharia de Controle e Automação", label: translate("Engenharia de Controle e Automação") },
                  { value: "Engenharia de Energia", label: translate("Engenharia de Energia") },
                  { value: "Engenharia de Materiais", label: translate("Engenharia de Materiais") },
                  { value: "Engenharia de Minas", label: translate("Engenharia de Minas") },
                  { value: "Engenharia de Produção", label: translate("Engenharia de Produção") },
                  { value: "Estatística", label: translate("Estatística") },
                  { value: "Farmácia", label: translate("Farmácia") },
                  { value: "Filosofia", label: translate("Filosofia") },
                  { value: "Fisioterapia", label: translate("Fisioterapia") },
                  { value: "Fonoaudiologia", label: translate("Fonoaudiologia") },
                  { value: "Física", label: translate("Física") },
                  { value: "Geografia", label: translate("Geografia") },
                  { value: "Geologia", label: translate("Geologia") },
                  { value: "História", label: translate("História") },
                  { value: "História da Arte", label: translate("História da Arte") },
                  { value: "Letras", label: translate("Letras") },
                  { value: "Matemática", label: translate("Matemática") },
                  { value: "Medicina", label: translate("Medicina") },
                  { value: "Veterinária", label: translate("Veterinária") },
                  { value: "Museologia", label: translate("Museologia") },
                  { value: "Música", label: translate("Música") },
                  { value: "Nutrição", label: translate("Nutrição") },
                  { value: "Odontologia", label: translate("Odontologia") },
                  { value: "Pedagogia", label: translate("Pedagogia") },
                  { value: "Políticas Públicas", label: translate("Políticas Públicas") },
                  { value: "Psicologia", label: translate("Psicologia") },
                  { value: "Química", label: translate("Química") },
                  { value: "Relações Internacionais", label: translate("Relações Internacionais") },
                  { value: "Saúde Coletiva", label: translate("Saúde Coletiva") },
                  { value: "Serviço Social", label: translate("Serviço Social") },
                  { value: "Teatro", label: translate("Teatro") },
                  { value: "Zootecnia", label: translate("Zootecnia") },
                  { value: 'Outra', label: translate('Outra') },
                ]} />


              : <></>}

            { /* (position === 'Estudante' || position === 'Profissional da área da saúde' || position === 'Outro? Qual?') &&
              <Input
                name="especialidade"
                placeholder="Especifique"
              />
              */  }


            <SelectSimple setCallback={(value) => setOtherCompany(value)}
              options={[
                { value: 'Dasa', label: 'Dasa' },
                { value: 'Outro? Qual?', label: translate('Outra?') },
              ]}
              name="company"
              formRef={formRef}
              label={translate("Organização")}
            />

            {otherCompany === 'Outro? Qual?' &&

              <Input
                name="extra_4"
                placeholder={translate("Informe o nome da organização")}
              />
            }


            <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={translate('Pais')} />


            {countryISO !== 'BR' ?

              <SelectSimple options={[
                { value: 'passport', label: translate('Passaporte') },
                { value: 'other', label: translate('Documento local') },
              ]} name="document_type" label={translate('Tipo de documento pessoal')}
              />



              : <></>}

            {countryISO === 'BR' ?
              <MaskedInput
                mask={'999.999.999-99'}
                name="document_number"
                placeholder={'CPF'}
              />
              : <Input type='text' name="document_number" placeholder={translate("Número do documento")} />}

            <MaskedInput
              mask="99/99/9999"
              name="birth_date"
              placeholder={translate("Data de nascimento (DD/MM/AAAA)")}
            />


            {withPassword && <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder={translate('Cadastre sua senha de acesso')}
            />}



            {countryISO === 'BR' ? <SelectSimple setCallback={(value) => {
              setStateValue(value);
            }} options={states} name="state" label={translate("Estado")} /> :

              <Input name="state" placeholder={translate('Província / Região da Fundação')} />}



            {countryISO === 'BR' ? <MaskedInput
              mask="(99) 999999999"
              name="phone"
              placeholder="Telefone com DDD"
            /> : <Input name="phone" placeholder={translate('Telefone com DDD')} />}

            <SelectSimple

              options={[
                { label: translate('Visitação Médica'), value: 'Visitação Médica' },
                { label: translate('NAM - Núcleo de Assessoria Médica'), value: 'NAM - Núcleo de Assessoria Médica' },
                { label: translate('WhatsApp'), value: 'WhatsApp' },
                { label: translate('Indicação'), value: 'Indicação' },
                { label: translate('Instagram'), value: 'Instagram' },
                { label: translate('Facebook'), value: 'Facebook' },
                { label: translate('Linkedin'), value: 'Linkedin' },
                { label: translate('E-mail marketing'), value: 'E-mail marketing' },
                { label: translate('SMS'), value: 'SMS' },
                { label: translate('Workplace'), value: 'Workplace' },
                { label: translate('Google'), value: 'Google' },
                { label: translate('Outros'), value: 'Outros' },





              ]}
              name="know_about"
              formRef={formRef}
              label={window?.location?.pathname?.indexOf('/biblioteca') >= 0 ? translate("Como ficou sabendo do evento/conteúdo científico?") : translate("Como ficou sabendo do evento?")}
            />



            <SelectSimple

              options={[
                { label: translate('Sim'), value: 'Sim' },
                { label: translate('Não'), value: 'Não' },


              ]}
              name="extra_5"
              formRef={formRef}
              label={window?.location?.pathname?.indexOf('/biblioteca') >= 0 ? translate("Gostaria de receber futuras comunicações sobre eventos/novos conteúdos publicados?") : translate("Gostaria de receber futuras comunicações sobre eventos?")}
            />


            {product && product?.fields && product?.fields.length > 0 ? <aside style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', margin: '30px 0px', border: '2px 0px #ddd' }}> <h2 style={{ fontSize: '12px', color: '#333', margin: '10px 0px' }}>{translate("Dados específicos do evento")}</h2>  <CompleteSaleFields fields={product.fields || []} /></aside> : <> </>}


            <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>
              <CheckboxInput
                style={{ display: 'flex' }}
                name="term"
                unique={true}
                preText={(
                  <>
                    {' '}
                    Estou ciente de que estou fornecendo à DASA EDUCA, parceiros e patrocinadores meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
                    {' '}

                  </>
                )}
                options={[
                  {
                    id: `Sim, eu autorizo o compartilhamento dos meus dados`,
                    label: translate('Sim, eu autorizo o compartilhamento dos meus dados'),
                    value: `Sim, eu autorizo o compartilhamento dos meus dados`,
                  },
                  {
                    id: `Não autorizo o compartilhamento dos meus dados`,
                    label: translate('Não autorizo o compartilhamento dos meus dados'),
                    value: `Não autorizo o compartilhamento dos meus dados`,
                  },
                ]}
              />

              <p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li a')}
                {' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('Política de Privacidade')}
                    {' '}
                  </Link>
                  {' '}
                </strong>
                {translate('desta plataforma.')}
              </p>
            </div>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButton" type="submit">
                {translate("Finalizar inscrição")}


              </button>
            </aside>
          </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;

import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container, CertificateLine, Error } from './styles';
import Input from '../../components/Forms/Input';
import { useToast } from '../../hooks/Toast';
import { Button } from '../../components/Button/styles';
import api from '../../services/api';
import Loader from '../../components/Loader';
import { hash, urlWebsite } from '../../config';
import CheckboxInput from '../../components/Forms/CheckboxInput';
import * as Yup from 'yup';
import { useLanguage } from '../../hooks/Language';
import { useAuth } from '../../hooks/Auth';
import getValidationErrors from '../../utils/getValidationErrors';

import Research from './FormContainer';
import Exam from './FormContainer2';
import { MdError } from 'react-icons/md';
import { useModal } from '../../hooks/Modal';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/Configuration';
import { handleCertificateDownload } from '../DashboardPages/pages/CertificatesUsers/modules/ExportPDF';
import QRCode from 'qrcode.react';
import SignInReload from '../SignInReload';
import { FaCertificate, FaCheckCircle, FaCheckDouble, FaDownload, FaIdBadge, FaReceipt } from 'react-icons/fa';
interface ICertificates {
  project_id: string;
  certificate_id_response: { title: string };
  hash: string;
  name?: string;
}

interface IOptions {
  id: string;
  value: string;
  label: string;


}




interface IAnswers {
  id: string;
  value: string;
  label: string;
}

interface IQuestion {
  title: string;
  options: Array<{ question: string, value: string }>;
  correct: string;
}

interface IQuestions {
  title: string;
  questions: Array<IQuestion>
}

interface IResponse {
  number: number;
  answers: Array<string>;
}

interface ICertificateUser {
  id: string;
  answers: Array<Record<string, any>>;
  hash: string;
  certificate_id_response: {
    proof: IQuestions
  },
  user_id: number;
}





const Certificados: React.FC = () => {
  const [certificates, setCertificates] = useState<Array<ICertificates>>([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { translate, language } = useLanguage();
  const { user } = useAuth();
  const [success, setSuccess] = useState(true);
  const [getNumber, setGetNumber] = useState(0);
  const [emailInfo, setEmailInfo] = useState('');
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const { addModal } = useModal();
  const { projectHash } = useParams<Record<string, string>>();
  const { configuration } = useConfiguration();
  const [certificate, setCertificate] = useState<ICertificateUser>({} as ICertificateUser);

  const verify = async () => {
    setLoading(true);

    try {


      await api.post(`/check-new-certificates`, { project_id: projectHash });
      await verifyOnLoad();
      setLoading(false);
    } catch (err) {

      setLoading(false);
      return addToast({
        title: translate('Nenhum certificado encontrado'),
        type: 'error',
      });
    }
  };



  const verifyOnLoad = async () => {
    setLoading(true);

    try {
      const response = await api.post(`/check-listed-certificates`);
      if (!response) {
        setLoading(false);
        return addToast({
          title: translate('Nenhum certificado encontrado'),
          type: 'error',
          description: translate('Verifique se inseriu o nome ou email correto. Caso esteja procurando pelo nome, utilize acentos onde necessário (Ex: João)')
        });
      }




      setCertificates(response.data.content);

      setSuccess(true);

      setActive(true);
      setLoading(false);
    } catch (err) {

      setLoading(false);
      return addToast({
        title: translate('Nenhum certificado encontrado'),
        type: 'error',
      });
    }
  };

  const [questions, setQuestions] = useState<IQuestions>({} as IQuestions);

  const resetVerify = () => {
    setCertificates([]);
    setActive(false);
  };

  const checkAnswers = (data) => {

    const response: IResponse = {
      number: 0,
      answers: []
    }

    let x = 1;
    console.log(certificate?.certificate_id_response)
    certificate?.certificate_id_response?.proof?.questions.map((question) => {

      if (data[`p${x}`] && data[`p${x}`][0] === question.correct) { response.number = response.number + 1; }
      response.answers.push(data[`p${x}`][0]);

      x = x + 1;

    })

    return response;


  }




  const handleCertificate = async (e, cert) => {
    e.preventDefault();
    setLoading(true);


    /* verificar se a pesquisa foi criada */


    if (cert?.certificate_id_response?.satisfaction_survey_id) {

      const researchCheck = await api.get(`/check-research/${cert.email}/${cert?.certificate_id_response?.satisfaction_survey_id}`);

      if (researchCheck?.data?.message !== 2) {
        addModal({
          theme: 'whiteModal max700',
          title: '',
          content: <Research cert={cert} handle={() => handleCertificate(e, cert)} survey_id={cert?.certificate_id_response?.satisfaction_survey_id} content={researchCheck?.data?.content} id={cert.email} />,
          key: 'research',

        });
        setLoading(false);
        return;
      }

      if (cert?.certificate_id_response?.exam_id) {
        const researchCheck = await api.get(`/check-exam/${cert.user_id}/${cert?.certificate_id_response?.exam_id}`);

        if (researchCheck?.data?.message !== 2) {
          addModal({
            theme: 'whiteModal max700',
            title: '',
            content: <Exam handle={() => handleCertificate(e, cert)} exam_id={cert?.certificate_id_response?.exam_id} content={researchCheck?.data?.content} id={cert.user_id} />,
            key: 'exam',

          });
          setLoading(false);
          return;
        }

        else {
          handleCertificateDownload({ certificate: cert });
          // window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
          setLoading(false);
        }


      }
      else {
        handleCertificateDownload({ certificate: cert });
        // window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
        setLoading(false);
      }


    }

    else if (cert?.certificate_id_response?.exam_id) {
      const researchCheck = await api.get(`/check-exam/${cert.user_id}/${cert?.certificate_id_response?.exam_id}`);

      if (researchCheck?.data?.message !== 2) {
        addModal({
          theme: 'whiteModal',
          title: '',
          content: <Exam handle={() => handleCertificate(e, cert)} exam_id={cert?.certificate_id_response?.exam_id} content={researchCheck?.data?.content} id={cert.user_id} />,
          key: 'exam',

        });
        setLoading(false);
        return;
      }

      else {
        handleCertificateDownload({ certificate: cert });
        // window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
        setLoading(false);
      }


    }

    else {
      handleCertificateDownload({ certificate: cert });
      // window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
      setLoading(false);

    }



  }

  const currentCertificates = certificates?.filter((cert) => cert?.project_id === projectHash);

  const otherProjects = certificates?.filter((cert) => cert?.project_id !== projectHash)


  useEffect(() => {

    if (user) {
      verifyOnLoad();
    }

  }, [user])

  if (!user) {

    return <SignInReload />

  }



  return (
    <Container style={{ background: '#fff' }}>

      <button style={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center', width: '100%', maxWidth: '300px', borderRadius: '15px', border: '1px solid #333', padding: '15px 10px', background: '#fff', color: 'rgb(0,14,64)' }}
        onClick={() => verify()}

        type="button"
      >
        <FaCheckCircle style={{ fontSize: '18px', color: 'rgb(0,14,64)' }} />
        <p style={{ color: 'rgb(0,14,64)', fontSize: '14px' }}>{translate("Verificar novos certificados")}</p>
      </button>

      <>
        <h2 style={{ textAlign: 'center', fontSize: '18px', margin: '20px 0px', marginTop: '30px', color: 'rgb(0, 14, 64)' }}>{`Meus certificados`}</h2>


        <div style={{ width: '100%', maxWidth: '550px', display: 'flex', gap: '15px', marginBottom: '15px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: '#fefefe', padding: '30px 15px', border: '1px solid #ddd', borderRadius: '15px' }}>
          <h2 style={{ textAlign: 'center', fontSize: '14px', marginBottom: '20px', color: 'rgb(0, 14, 64)' }}>{`${configuration?.title}`}</h2>

          {configuration?.theme_data?.certificate_text ? <p className='orientation' style={{ color: '#333', margin: '0px auto', maxWidth: '500px', textAlign: 'center' }}>{configuration?.theme_data?.certificate_text}</p> : <></>}
          {configuration?.current_event_id_response?.certificate_description ? <p className='orientation' style={{ color: '#333', margin: '0px auto', maxWidth: '500px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: configuration?.current_event_id_response?.[`certificate_description_${language}`] ? configuration?.current_event_id_response?.[`certificate_description_${language}`] : configuration?.current_event_id_response?.certificate_description }} /> : <></>}


          <div>
            {currentCertificates && currentCertificates.length > 0 ? (
              currentCertificates.map(cert => (
                <CertificateLine onClick={(e) => handleCertificate(e, cert)}>

                  <FaDownload style={{ fontSize: '24px', color: '#333', marginLeft: '10px' }} />
                  <aside style={{ display: 'none' }}>
                    <QRCode id={`canva-${cert.hash}`}
                      size={100}
                      value={`${urlWebsite}/validar-certificado/${cert.hash}`} renderAs="canvas" />
                  </aside>

                  <p><strong>{cert?.certificate_id_response?.title || ''}</strong><br />
                    Parcipante: {cert?.name}</p>
                </CertificateLine>
              ))
            ) : (
              <CertificateLine  >


                <p><strong>{translate('Nenhum certificado encontrado')}</strong></p>

              </CertificateLine>
            )}



          </div>
        </div>

        <div style={{ width: '100%', maxWidth: '550px', display: 'flex', gap: '15px', marginBottom: '15px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: '#fefefe', padding: '30px 15px', border: '1px solid #ddd', borderRadius: '15px' }}>
          <h2 style={{ textAlign: 'left', fontSize: '14px', marginBottom: '20px', color: 'rgb(0, 14, 64)' }}>{`${translate("Certificados de outros eventos")}`}</h2>
          <div>
            {otherProjects && otherProjects.length > 0 ? (
              otherProjects.map(cert => (
                <CertificateLine onClick={(e) => handleCertificate(e, cert)}>

                  <FaDownload style={{ fontSize: '24px', color: '#333', marginLeft: '10px' }} />
                  <aside style={{ display: 'none' }}>
                    <QRCode id={`canva-${cert.hash}`}
                      size={100}
                      value={`${urlWebsite}/validar-certificado/${cert.hash}`} renderAs="canvas" />
                  </aside>

                  <p><strong>{cert?.certificate_id_response?.title || ''}</strong><br />
                    Parcipante: {cert?.name}</p>
                </CertificateLine>
              ))
            ) : (
              <CertificateLine  >


                <p><strong>{translate('Nenhum certificado encontrado')}</strong></p>

              </CertificateLine>
            )}
          </div>
        </div>
      </>


      {loading && <Loader message={translate("Verificando")} />}


    </Container>
  );
};
export default Certificados;

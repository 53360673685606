import React, { useCallback, useRef, useState } from 'react';
import { FiMail, FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { logo, recaptchaKey } from '../../config';
import { Container, Content, Background, AnimatedDiv } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/Toast';
import api from '../../services/api';
import { ResponseMessage } from '../../components/ResponseMessage';
import { useConfiguration } from '../../hooks/Configuration';

interface IRecoverFormData {
  email: string;
  captcha: string;
}

interface ICaptcha {
  getValue(): string;
}

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [message, setMessage] = useState('');
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, eventHash } = useParams<Record<string, any>>();
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const handleSubmit = useCallback(
    async (data: IRecoverFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('O campo Email é obrigatório')
            .email('Preencha um email válido'),
        });

        await schema.validate(data, { abortEarly: false });

        const captchaValue =
          getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
          false;

        /*   if (!captchaValue) {
          addToast({
            type: 'error',
            title: 'Pressione o Captcha',
          });
          return;
        } */

        const response = await api.post('/recover-password', {
          email: data.email,
          captcha: captchaValue,
          project_id: projectHash,
          event_id: eventHash
        });

        setMessage(response.data.message);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          description: err?.response?.data?.error || err?.response?.data?.message || 'Erro ao realizar recuperação de senha',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Background />

      <Content>
        <AnimatedDiv>
          <img src={logo} alt="Plataforma Online" style={{ width: '200px' }} />
          {message !== '' ? (
            <ResponseMessage
              active
              type="success"
              title="Sucesso"
              description={
                <p>
                  Você receberá um email com instruções para alterar sua senha.
                  Esta solicitação expirará em 24 horas
                </p>
              }
            />
          ) : (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h2 style={{ color: '#333', margin: '10px auto' }}>Recuperação de Senha</h2>

              <Input icon={FiMail} name="email" placeholder="E-mail" />
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

              <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Button type="submit">Recuperar Senha</Button>
              </aside>
            </Form>
          )}

          <Link style={{ color: '#fff', background: 'rgb(0,15,64)', padding: '10px', borderRadius: '15px' }} to={eventHash ? `/app/${projectHash}/event/${eventHash}/login` : projectHash ? `/app/${projectHash}/login` : "/login"}>
            <FiArrowLeft />
            Voltar para a capa
          </Link>
        </AnimatedDiv>
      </Content>
    </Container>
  );
};

export default ResetPassword;
